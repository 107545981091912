/**
 * Global css/js requirements for the whole application
 */

// css requirements
require('../css/app.scss');

// js requirements (ECMA6)
import 'bootstrap/dist/js/bootstrap.bundle'

// app stuff
//import * as mm from './mm'
//window.mm = mm;


